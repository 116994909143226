import { z } from "zod";

export const baseModels = [
  { name: "text-davinci-003", shortName: "davinci", token: 4000 },
  { name: "text-curie-001", shortName: "curie", token: 2048 },
  { name: "text-babbage-001", shortName: "babbage", token: 2048 },
  { name: "text-ada-001", shortName: "ada", token: 2048 },
] as const;

export enum Provider {
  anthropic = "anthropic",
  openai = "openai",
  openrouter = "openrouter",
  pinecone = "pinecone",
}

export type LISP = string | LISP[];

export const zLisp: z.ZodType<LISP> = z.lazy(() =>
  z.union([z.string(), z.array(zLisp)])
);

export const validateLisp = (lisp: unknown): LISP | [] => {
  try {
    return zLisp.parse(lisp);
  } catch (e) {
    return [];
  }
};

export type Message = {
  role: "system" | "user" | "assistant";
  name?: string;
  content: string;
  timestamp: number;
  type?: string;
  action?: LISP;
  tokens?: number;
};

export const models = [
  {
    id: "gpt-4-0125-preview",
    name: "GPT-4-0125-preview",
    tokenLimit: 128000,
    provider: Provider.openai,
  },
  {
    id: "gpt-4o-mini",
    name: "GPT-4o mini - New!! 🔥",
    tokenLimit: 128000,
    provider: Provider.openai,
  },
  {
    id: "gpt-4o",
    name: "GPT-4-o",
    tokenLimit: 128000,
    provider: Provider.openai,
  },
  {
    id: "gpt-4-turbo",
    name: "GPT-4-turbo",
    tokenLimit: 128000,
    provider: Provider.openai,
  },
  {
    id: "gpt-3.5-turbo-0125",
    name: "GPT-3.5-turbo-0125",
    tokenLimit: 16385,
    provider: Provider.openai,
  },
  {
    id: "google/gemini-pro",
    name: "Gemini Pro (Google, via OpenRouter)",
    tokenLimit: 131000,
    provider: Provider.openrouter,
  },
  {
    id: "gpt-3.5-turbo-1106",
    name: "GPT 3.5 Turbo 16k",
    tokenLimit: 16000,
    provider: Provider.openai,
  },
  {
    id: "gpt-4-1106-preview",
    name: "GPT-4 Turbo 128k",
    tokenLimit: 128000,
    provider: Provider.openai,
  },
  {
    id: "gpt-4-0613",
    name: "GPT-4 (0613)",
    tokenLimit: 8000,
    provider: Provider.openai,
  },
  {
    id: "gpt-4-32k-0613",
    name: "GPT-4 32k (0613)",
    tokenLimit: 32000,
    provider: Provider.openai,
  },
  {
    id: "anthropic/claude-3.5-sonnet",
    name: "Claude 3.5 Sonnet (Anthropic, via OpenRouter) - New!! 🔥",
    tokenLimit: 200000,
    provider: Provider.openrouter,
  },
  {
    id: "anthropic/claude-3-opus",
    name: "Claude 3 Opus (Anthropic, via OpenRouter)",
    tokenLimit: 200000,
    provider: Provider.openrouter,
  },
  {
    id: "anthropic/claude-3-sonnet",
    name: "Claude 3 Sonnet (Anthropic, via OpenRouter)",
    tokenLimit: 200000,
    provider: Provider.openrouter,
  },
  {
    id: "mistralai/Mistral-7B-Instruct-v0.1",
    name: "Mistral 7B (Free 😱, via OpenRouter)",
    tokenLimit: 8000,
    provider: Provider.openrouter,
  },
  {
    id: "claude-instant-1.1",
    name: "Claude Instant 100k",
    tokenLimit: 99000,
    provider: Provider.anthropic,
  },
  {
    id: "claude-2.0",
    name: "Claude 2 100k",
    tokenLimit: 99000,
    provider: Provider.anthropic,
  },
  {
    id: "openai/gpt-3.5-turbo",
    name: "GPT-3.5 Turbo (OpenAI, via OpenRouter)",
    tokenLimit: 4000,
    provider: Provider.openrouter,
  },
  {
    id: "openai/gpt-3.5-turbo-16k",
    name: "GPT-3.5 Turbo 16k (OpenAI, via OpenRouter)",
    tokenLimit: 16000,
    provider: Provider.openrouter,
  },
  {
    id: "openai/gpt-4",
    name: "GPT-4 (OpenAI, via OpenRouter)",
    tokenLimit: 8000,
    provider: Provider.openrouter,
  },
  {
    id: "openai/gpt-4-32k",
    name: "GPT-4 32k (OpenAI, via OpenRouter)",
    tokenLimit: 32000,
    provider: Provider.openrouter,
  },
  {
    id: "anthropic/claude-2",
    name: "Claude 2 100k (Anthropic, via OpenRouter)",
    tokenLimit: 99000,
    provider: Provider.openrouter,
  },
  {
    id: "anthropic/claude-instant-v1",
    name: "Claude Instant 100k (Anthropic, via OpenRouter)",
    tokenLimit: 99000,
    provider: Provider.openrouter,
  },
  {
    id: "google/palm-2-chat-bison",
    name: "Google: PaLM 2 Bison",
    tokenLimit: 8000,
    provider: Provider.openrouter,
  },
  {
    id: "google/palm-2-codechat-bison",
    name: "Google: PaLM 2 Bison (Code)",
    tokenLimit: 8000,
    provider: Provider.openrouter,
  },
  {
    id: "meta-llama/llama-3-70b-instruct",
    name: "Llama 3 70b-instruct (Meta, via OpenRouter) - New!! 🔥",
    tokenLimit: 4000,
    provider: Provider.openrouter,
  },
  {
    id: "meta-llama/llama-2-70b-chat",
    name: "Llama 2 70b (Meta, via OpenRouter)",
    tokenLimit: 4000,
    provider: Provider.openrouter,
  },
  {
    id: "meta-llama/llama-2-13b-chat",
    name: "Llama 2 13b (Meta, via OpenRouter)",
    tokenLimit: 4000,
    provider: Provider.openrouter,
  },
  {
    id: "nousresearch/nous-hermes-llama2-13b",
    name: "Hermes Llama 2 13b (Nous, via OpenRouter)",
    tokenLimit: 4000,
    provider: Provider.openrouter,
  },
] as const satisfies readonly {
  id: string;
  name: string;
  tokenLimit: number;
  provider: Provider;
}[];

export type Model = (typeof models)[number];
export type ModelId = Model["id"];

export const getTokenLimit = (modelId: ModelId) => {
  const model = models.find((model) => model.id === modelId) ?? models[0];

  return model.tokenLimit;
};

export const getProvider = (modelId: ModelId) => {
  const model = models.find((model) => model.id === modelId) ?? models[0];

  return model.provider;
};

export const getDefaultStop = (modelId: ModelId) => {
  switch (modelId) {
    case "meta-llama/llama-2-13b-chat":
    case "meta-llama/llama-2-70b-chat":
    case "nousresearch/nous-hermes-llama2-13b":
      return ["<human>", "<bot>", "</human>", "</bot>"];
    case "mistralai/Mistral-7B-Instruct-v0.1":
      return ["DONE"];
    default:
      return ["User: "];
  }
};

export const temporaryMagicSeparator = "\n\n~~~~~~~!!~$#<|>#$~!!~~~~~~~\n\n";
